#main.contact {
    .contact-info {
        padding: 125px 0 0 0;
        text-align: center;

        .header-title {
            padding: 0 40px 65px 40px;
        }

        .page-subtitle {
            margin: 30px 0 0 0;
        }

        .intro {
            margin: 20px 0 30px 0;
        }

        .btn {
            display: inline-block;
        }
    }

    .basic-info {
        background: $gradient-background;
    }

    .info-list {
        color: $color-white;
        display: flex;
        text-align: left;
        line-height: 1.7;
        padding: 50px 0;

        .info-item {
            margin: 0 40px;
            width: calc(25% - 40px);
        }

        .title {
            align-items: center;
            border-bottom: 2px solid rgba($color-white, .25);
            display: flex;
            margin: 5px 0 25px 0;
            padding-bottom: 30px;
        }

        .icon {
            fill: $color-icon-light;
            height: 50px;
            margin-right: 20px;
            width: 50px;
        }

        dt {
            clear: both;
            display: inline-block;
            float: left;
            font-weight: 700;
        }

        dd {
            display: inline-block;
            float: left;
            margin-left: 10px;
        }

        address {
            font-style: normal;
        }
    }

    .contact-map-block {
        background: url('../images/mapa.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 60px 0;

        .alert {
            background: $color-white;
            border-radius: 6px;
            box-shadow: $box-shadow-contact;
            color: $color-success;
            padding: 15px 20px;
            margin-bottom: 15px;
            max-width: 640px;
            width: 100%;
        }
    }

    .contact-form {
        background: $color-white;
        border-radius: 6px;
        box-shadow: $box-shadow-contact;
        display: flex;
        height: 620px;
        flex-wrap: wrap;
        padding: 55px 40px;
        max-width: 640px;
        width: 100%;

        .page-subtitle {
            color: $color-text;
            margin: 0 0 30px 0;
            padding-left: 12px;
            text-align: left;
            width: 100%;
        }

        .btn {
            margin-left: auto;
            margin-top: 25px;
            transform: translateX(-12px);
        }
    }
}

@media screen and (max-width: 1300px) {
    #main.contact {
        .info-list {
            flex-wrap: wrap;
            padding: 30px 0;

            .info-item {
                margin: 20px 40px;
                width: calc(50% - 80px);
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    #main.contact {
        .contact-info {
            padding: 60px 0 0 0;

            .header-title {
                padding: 0 30px 50px 30px;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    #main.contact {
        .contact-info {
            .header-title {
                padding: 0 30px 50px 30px;
            }
        }
    }
}

@media screen and (max-width: 640px) {
    #main.contact {
        .info-list {
            flex-wrap: wrap;
            padding: 30px;

            .info-item {
                margin: 20px auto;
                max-width: 300px;
                width: 100%;

                .title {
                    font-size: 1.7rem;
                    margin-bottom: 15px;
                    padding-bottom: 20px;
                }
            }

            .icon {
                height: 35px;
                margin-right: 15px;
                width: 35px;
            }
        }

        .contact-map-block {
            padding: 40px 0;
        }

        .contact-form {
            padding: 45px 30px;
        }
    }
}

@media screen and (max-width: 560px) {
    #main.contact {
        .contact-info {
            .header-title {
                padding: 0 20px 50px 20px;
            }

            .btn {
                width: 100%;
            }
        }

        .contact-form {
            height: auto;
            padding: 35px 20px;

            .page-subtitle {
                padding-left: 0;
            }

            .form-item {
                margin: 0;
                width: 100%;
            }

            .checkbox-container {
                margin: 0;
            }

            .btn {
                transform: translateX(0);
                width: 100%;
            }
        }
    }
}
