#main.pricetable {
    padding: 120px 0 0 0;

    .header-title {
        text-align: center;
        padding: 0 20px 75px 20px;
    }

    .pricetable-info {
        position: relative;

        &:before {
            background: $gradient-background;
            content: '';
            display: block;
            height: 460px;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
        }

        .wrapper {
            padding-bottom: 60px;
            padding-top: 60px;
            position: relative;
            z-index: 1;
        }

        .intro {
            color: $color-white;
            font-size: 2.1rem;
            line-height: 1.2;
            margin: auto;
            max-width: 680px;
            text-align: center;
            width: 100%;

            &.gray {
                color: darken($color-gray, 5%);
                font-size: 2.1rem;
                margin: 40px auto 20px auto;
            }
        }

        .links-wrapper {
            margin-top: 70px;
            text-align: center;

            .link {
                background: none;
                border: none;
                cursor: pointer;
                color: $color-white;
                display: inline-block;
                font-size: 2.1rem;
                font-weight: 700;
                margin: 0 18px;
                outline: none;
                padding: 25px 0;
                position: relative;
                text-align: center;
                transition: $transition-basic;
                width: 240px;

                .discount {
                    background: darken($color-text, 6%);
                    color: $color-secondary-text-light;
                    display: block;
                    top: -30px;
                    display: block;
                    font-size: 1.8rem;
                    font-weight: 600;
                    left: 50%;
                    padding: 7px 14px;
                    position: absolute;
                    text-transform: uppercase;
                    transform: translateX(-50%) translateY(0);
                    width: 180px;

                    .discount-mobile-label {
                        display: none;
                    }

                    &:after {
                        background: darken($color-text, 6%);
                        bottom: -4px;
                        content: '';
                        display: block;
                        height: 10px;
                        left: 50%;
                        position: absolute;
                        transform: rotate(45deg);
                        width: 10px;
                    }
                }

                &.active {
                    .discount {
                        animation: discountPopup .25s ease-out;
                    }
                }

                &:after,
                &:before {
                    background: rgba($color-dark, .3);
                    border-radius: 3px;
                    bottom: -2px;
                    content: '';
                    display: block;
                    height: 5px;
                    left: 0;
                    position: absolute;
                    transition: $transition-basic;
                    width: 100%;
                }

                &:before {
                    background: $color-white;
                    border-radius: 3px;
                    bottom: -2px;
                    content: '';
                    display: block;
                    height: 5px;
                    left: 50%;
                    position: absolute;
                    transition: $transition-basic;
                    transform: translateX(-50%);
                    width: 0%;
                    z-index: 1; 
                }

                &:hover,
                &.active {
                    &:before {
                        width: 100%;
                    }
                }
            }
        }

        .pricetable {
            align-items: stretch;
            display: flex;
            color: $color-white;
            margin-top: 25px;

            .container {
                padding: 30px 20px;
                width: 50%;

                &:first-of-type {
                    .price-block {
                        .tooltip-wrapper {
                            right: 33%;
                        }
                    }
                }
            }

            &.active {
                display: flex;
            }

            .price-block {
                background: darken($color-white, 3%);
                border-radius: 6px;
                border: 1px solid $color-border-light;
                color: $color-dark;
                padding: 40px 45px;
                position: relative;
                transition: $transition-basic;

                .icon {
                    bottom: 55px;
                    left: 50%;
                    position: absolute;
                    transform: translateX(-50%);

                    &.small-box {
                        height: 32px;
                        width: 31px;
                    }

                    &.big-box {
                        height: 51px;
                        width: 50px;
                    }

                    &.three-boxes {
                        height: 54px;
                        width: 80px;
                    }
                }

                .block-subtitle {
                    color: $color-text;
                    font-size: 2.8rem;
                    font-weight: 600;
                    line-height: 1.8;
                    margin: 70px 0 10px 0;
                    position: relative;
                    text-align: center;
                }

                .tooltip-wrapper {
                    height: 20px; 
                    position: absolute;
                    right: 27%;
                    top: 115px;
                    width: 20px;

                    .tooltip-icon {
                        align-items: center;
                        background: $color-primary;
                        border-radius: 50%;
                        color: $color-white;
                        display: flex;
                        font-size: 14px;
                        font-weight: 700; 
                        height: 100%;           
                        justify-content: center;
                        left: 0;
                        position: absolute;
                        text-align: center;
                        transition: $transition-basic;
                        top: 0;
                        width: 100%;
                    }

                    .desc {
                        background: darken($color-text, 6%);
                        bottom: 27px;
                        color: $color-secondary-text-light;
                        display: block;
                        font-size: 1.4rem;
                        font-weight: 400;
                        left: 50%;
                        line-height: 1.5;
                        margin: 0;
                        opacity: 0;
                        pointer-events: none;
                        position: absolute;
                        padding: 7px 14px;
                        text-align: center;
                        transition: $transition-basic;
                        transform: translateX(-50%) translateY(0);
                        width: 250px;

                        &:after {
                            background: darken($color-text, 6%);
                            bottom: -4px;
                            content: '';
                            display: block;
                            height: 10px;
                            left: 50%;
                            position: absolute;
                            transform: translateX(-50%) rotate(45deg);
                            width: 10px;
                        }
                    }

                    &:active,
                    &:focus,
                    &:hover {
                        .tooltip-icon {
                            background: darken($color-text, 6%);
                        }

                        .desc {
                            opacity: 1;
                        }
                    }
                }

                .price {
                    color: $color-secondary-text;
                    font-size: 3.6rem;
                    margin: 10px 0;
                    text-align: center;

                    s {
                        color: darken($color-gray, 15%);
                        display: inline-block;
                        font-size: 2.1rem;
                        opacity: 0;
                        overflow: hidden;
                        position: relative;
                        top: 4px;
                        transition: width .25s ease-out, opacity .2s ease-out;
                        white-space: nowrap;
                        width: 0;

                        &.is-visible {
                            opacity: 1;
                            width: 72px;
                        }
                    }

                    &.gray {
                        color: darken($color-gray, 15%);
                        margin-bottom: 40px;
                    }

                    small {
                        color: darken($color-gray, 15%);
                        display: block;
                        font-size: 1.6rem;
                    }
                }

                .options-item {
                    color: $color-text-dark;
                    border-top: 1px solid $color-border-light;
                    font-weight: 600;
                    padding: 12px 0 12px 30px;
                    position: relative;

                    .icon {
                        fill: darken($color-gray, 12%);
                        height: 15px;
                        left: 8px;
                        position: absolute;
                        top: 14px;
                        width: 14px;
                    }

                    small {
                        color: darken($color-gray, 12%);
                        display: block;
                        margin-top: 5px;
                    }

                    &.disabled {
                        .icon {
                            fill: lighten($color-gray, 10%);
                        }

                        small {
                            color: lighten($color-gray, 12%);
                        }

                        span {
                            color: darken($color-gray, 12%);
                            text-decoration: line-through;
                        }
                    }
                }

                .btn {
                    border: none;
                    box-shadow: $box-shadow-contact;
                    display: block;
                    margin: 30px auto 0 auto;
                    padding: 12px 25px;
                    text-align: center;
                    transition: $transition-basic;
                    width: 100%;
                }

                &:active,
                &:focus,
                &:hover {
                    background: $color-white;
                    border: 1px solid transparent;
                    box-shadow: $box-shadow-contact;

                    .btn {
                        background: $gradient-secondary-hover;
                        box-shadow: $box-shadow-hover;
                        color: $color-white;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    #main.pricetable {
        .pricetable-info {
            .pricetable {
                .container {
                    padding: 30px 5px;
                }

                .price-block {
                    padding: 20px;

                    .tooltip-wrapper {
                        right: 25%;
                        top: 95px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    #main.pricetable {
        padding: 60px 0 0 0;

        .tab {
            display: flex;
            justify-content: center;
        }

        .pricetable-info {
            .tab-block {
                .tabcontent {
                    .container {
                        padding: 30px 5px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 920px) {
    #main.pricetable {
        .pricetable-info {
            .links-wrapper {
                flex-wrap: wrap;
                margin: 20px auto 0 auto;
                max-width: 480px;
                width: 100%;

                .link {
                    .discount {
                        top: 20px;
                        left: 150%;

                        &:after {
                            bottom: 11px;
                            left: -5px;
                        }
                    }
                }
            }

            .pricetable {
                flex-wrap: wrap;
                flex-direction: column-reverse;

                .price-block .tooltip-wrapper {
                    right: 20%;
                }

                .container {
                    margin: 0 auto;
                    max-width: 480px;
                    padding: 15px 20px;
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    #main.pricetable {
        .pricetable-info {
            .wrapper {
                padding-top: 40px;
            }

            .intro {
                font-size: 1.8rem;
            }

            .links-wrapper {
                .link {
                    .discount {
                        font-size: 1.5rem;
                        left: 140%;
                        padding: 6px 0;
                        top: 22px;
                        width: 140px;

                        &:after {
                            bottom: 9px;
                        }
                    }
                }
            }

            .tab {
                .tablinks {
                    font-size: 1.8rem;
                    padding: 20px 30px;

                    .discount {
                        font-size: 12px;
                        width: 128px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    #main.pricetable {
        .pricetable-info {
            .links-wrapper {
                max-width: 440px;
                text-align: left;

                .link {
                    margin: 0;
                    width: 50%;

                    .discount {
                        left: 168%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    #main.pricetable {
        .header-title {
            padding-bottom: 40px;

            .btn {
                display: inline-block;
                width: 100%;
            }
        }

        .pricetable-info {
            .intro {
                font-size: 1.6rem;
            }

            .tab {
                flex-wrap: wrap;
                margin-top: 20px;

                .tablinks {
                    margin-top: 40px;

                    &:first-child {
                        margin-top: 0;
                    }

                    .discount {
                        top: -20px;
                    }
                }
            }

            .pricetable {
                .container {
                    max-width: 100%;
                }
            }

            .links-wrapper {
                max-width: 100%;

                .link {
                    width: 100%;

                    .discount {
                        left: auto;
                        right: -20px;
                        width: 40px;

                        .discount-mobile-label {
                            display: inline;
                        }

                        .discount-desktop-label {
                            display: none;
                        }
                    }
                }
            }

            .pricetable {
                .container {
                    padding: 20px 0;
                }
            }
        }
    }
}

@keyframes discountPopup {
    from,
    to {
        transform: translateX(-50%) translateY(0) scale(1);
    }

    50% {
        transform: translateX(-50%) translateY(-5px) scale(1.1);
    }
}
