.brands {
    overflow: hidden;
    padding: 75px 0 20px 0;

    .brands-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 55px 0;

        .brands-item {
            height: 90px;
            margin: 20px;
            width: 200px;

            img {
                display: block;
                height: auto;
                opacity: .75;
                width: 100%;
            }
        }
    }

    .text-extra {
        font-size: 2.4rem;
        text-align: center;
    }
}

@media screen and (max-width: 1300px) {
    .brands {
        .text-extra {
            font-size: 2.1rem;
            line-height: 1.5;
        }
    }
}

@media screen and (max-width: 1040px) {
    .brands {
        padding: 55px 0 10px 0;

        .text-extra {
            font-size: 1.8rem;
        }

        .brands-list {
            margin: 40px 0;
        }
    }
}

@media screen and (max-width: 720px) {
    .brands {
        padding: 40px 0 10px 0;

        .brands-list {
            margin: 30px 0;
        }
    }
}

@media screen and (max-width: 560px) {
    .brands {
        padding: 30px 0 10px 0;

        .brands-list {
            margin: 20px 0;

            .brands-item {
                margin: 5px 0;
            }
        }
    }
}

@media screen and (max-width: 440px) {
    .brands {
        .brands-list {
            .brands-item {
                height: auto;
                margin: 10px 0;
                width: 50%;
            }
        }
    }
}
