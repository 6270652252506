.header {
    overflow: hidden;
    padding: 140px 0 100px 0;
    position: relative;

    &:before {
        background: $gradient-background;
        content: '';
        display: block;
        height: 50%;
        left: 0;
        position: absolute;
        top: 50%;
        width: 100%;
        z-index: 0;
    }

    .text-wrapper {
        margin: auto;
        max-width: 780px;
        text-align: center;
        padding: 0 30px;
        position: relative;
        width: 100%;
        z-index: 2;

        .container {
            align-items: center;
            display: flex;
            justify-content: center;

            .btn {
                margin: 0 20px;
                opacity: 0;
                padding: 16px 25px;
                transform: translateY(10px);
                transition: transform .5s ease-out, opacity .3s ease-out;
                transition-delay: .5s;
                width: 300px;

                & + .btn {
                    transition-delay: .75s;
                }
            }
        }
    }

    &.is-active {
        .text-wrapper {
            .container {
               .btn {
                    opacity: 1;
                    transform: translateY(0px);
                }
            }
        }
    }

    .photo-wrapper {
        padding-bottom: 38%;
        position: relative;
        z-index: 1;
        width: 100%;

        &:before {
            animation: none;
            border: 2px solid #ddd;
            border-top-color: transparent;
            border-radius: 50%;
            content: "";
            height: 32px;
            left: 50%;
            opacity: 0;
            position: absolute;
            top: 80px;
            transition: all .25s ease-out;
            transform: translateX(-50%);
            width: 32px;
        }

        &.is-loading {
            &:before {
                animation: loader 1s linear infinite;
                opacity: 1;
                top: 50px;
            }
        }

        img {
            opacity: 0;
            position: absolute;
            transform-origin: 50% 50%;
            transition: transform 1s ease-out, opacity .5s ease-out;
            z-index: 1;

            &.photo-wrapper-first-layer {
                z-index: 2;
            }
        }

        .tablet-white {
            left: 0;
            max-width: 400px;
            top: 50%;
            transform: scale(.5) translateX(-50%) translateY(-50%);
            transition-delay: .55s;
            width: 21%;
        }

        .tablet-black {
            max-width: 580px;
            right: 10%;
            top: -20%;
            transform: scale(.5);
            transition-delay: .45s;
            width: 30%;
        }

        .mug-green {
            left: 10%;
            max-width: 220px;
            top: 85%;
            transform: scale(.5) translateY(-85%);
            transition-delay: .35s;
            width: 12%;
        }

        .mug-blue {
            max-width: 220px;
            right: 1%;
            top: 35%;
            transform: scale(.5) translateY(-35%);
            transition-delay: .35s;
            width: 12%;
        }

        .phone-horizontal {
            left: 15%;
            max-width: 400px;
            top: 70%;
            transform: scale(.5) translateY(-70%);
            transition-delay: .25s;
            width: 22%;
        }

        .phone-vertical {
            left: 20%;
            max-width: 400px;
            top: 5%;
            transform: scale(.5);
            transition-delay: .25s;
            width: 22%;
        }

        .computer {
            left: 50%;
            max-width: 630px;
            top: 50%;
            transform: scale(.5) translateX(-80%) translateY(-45%);
            transition-delay: 0s;
            width: 40%;
        }

        .keyboard {
            max-width: 510px;
            right: -30px;
            top: 66%;
            transform: scale(.5) translateX(50%) translateY(-90%);
            transition-delay: .55s;
            width: 27%;
        }

        .glasses {
            max-width: 350px;
            right: 17%;
            top: 77%;
            transform: scale(.5) translateY(-77%);
            transition-delay: .45s;
            width: 18%;
        }

        &.is-active {    
            .tablet-white {
                opacity: 1;
                transform: scale(1) translateX(0) translateY(-50%);
            }
    
            .tablet-black {
                opacity: 1;
                transform: scale(1);
            }
    
            .mug-green {
                opacity: 1;
                transform: scale(1) translateY(-85%);
            }
    
            .mug-blue {
                opacity: 1;
                transform: scale(1) translateY(-35%);
            }
    
            .phone-horizontal {
                opacity: 1;
                transform: scale(1) translateY(-70%);
            }
    
            .phone-vertical {
                opacity: 1;
                transform: scale(1);
            }
    
            .computer {
                opacity: 1;
                transform: scale(1) translateX(-50%) translateY(-50%);
            }
    
            .keyboard {
                opacity: 1;
                transform: scale(1) translateX(0) translateY(-66%);
            }
    
            .glasses {
                opacity: 1;
                transform: scale(1) translateY(-77%);
            }
        }
    }

    .header-options-block {
        color: $color-white;
        display: flex;
        line-height: 1.2;
        margin: 0 auto;
        max-width: 1315px;
        padding: 0 20px;
        position: relative;
        width: 100%;
        z-index: 1;

        .section-subtitle {
            margin: 0 0 30px 0;
        }

        .intro {
            font-size: 1.9rem;
        }

        .options-list {
            margin-top: 40px;

            .options-item {
                padding-left: 100px;
                position: relative;
                margin: 32px 0;

                .icon {
                    height: 46px;
                    left: 15px;
                    position: absolute;
                    top: 0;
                    width: 48px;

                    &.light {
                        fill: $color-icon-light;
                    }

                    &.dark {
                        fill: $color-icon-dark;
                    }
                }

                .description {
                    display: inline-block;
                    max-width: 240px;
                }
            }
        }

        .container {
            padding: 50px;
            border: 2px solid rgba($color-white, .1);
            position: relative;
            transition: all .25s ease-out;

            .intro {
                color: $color-dark;
                transition: all .25s ease-out;
            }

            .section-subtitle,
            .intro,
            .description {
                color: $color-white;
                transition: all .25s ease-out;
            }

            .icon {
                transition: all .25s ease-out;
            }

            &:first-child {
                border-radius: 6px 0 0 6px;
                border-right: none!important;
            }

            &:last-child {
                border-radius: 0 6px 6px 0;
                border-left: none!important;
            }

            &:first-child {
                &:after {
                    background: $color-white;
                    content: '';
                    display: block;
                    height: 20px;
                    left: calc(100% - 10px);
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%) rotate(45deg);
                    width: 20px;
                }
            }

            &.light {
                background: $color-white;
                border: 2px solid $color-white;
                color: $color-text;

                .section-subtitle,
                .intro,
                .description {
                    color: $color-text;
                }
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .header .photo-wrapper .computer {
        max-width: 780px;
    }

    .header .photo-wrapper .tablet-black {
        left: calc(50% + 200px);
        top: -15vh;
    }

    .header .photo-wrapper .phone-vertical {
        left: calc(50% - 640px);
        top: 4vh;
    }
}

@media screen and (max-width: 1300px) {
    .header {
        .page-subtitle {
            font-size: 4.5rem;
        }

        .intro {
            font-size: 2rem;
        }

        .header-options-block {
            .section-subtitle {
                font-size: 3rem;
            }

            .intro {
                font-size: 1.8rem;
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .header {
        padding-top: 80px;
        padding-bottom: 30px;

        .page-subtitle {
            font-size: 4rem;
        }

        .intro {
            font-size: 1.8rem;
        }

        .photo-wrapper {
            margin-top: 20px;
        }

        .header-options-block {
            margin-top: 40px;

            .container {
                padding: 30px;
            }

            .section-subtitle {
                font-size: 2.5rem;
            }

            .intro {
                font-size: 1.6rem;
            }

            .options-list {
                margin-top: 30px;

                .options-item {
                    padding-left: 60px;

                    .icon {
                        height: 36px;
                        left: 0;
                        width: 38px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .header {
        &:before {
            height: 60%;
            top: 40%;
        }

        .header-options-block {
            flex-direction: column;

            .container {
                &:first-child {
                    border: 1px solid $color-text;
                    border-right: 1px solid $color-text!important;
                    border-radius: 6px 6px 0 0;

                    &:after {
                        left: calc(50% - 10px);
                        top: calc(100% + 1px);
                    }

                    &:not(.light) {
                        border-color: rgba($color-white, .1)!important;
                    }
                }

                &:last-child {
                    border: 1px solid rgba($color-white, .1);
                    border-left: 1px solid rgba($color-white, .1)!important;
                    border-radius: 0 0 6px 6px;
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .header {
        padding-top: 60px;
        padding-bottom: 60px;

        .page-subtitle {
            font-size: 3.2rem;
        }

        .text-wrapper {
            .container {
                .btn {
                    margin: 0 10px;
                    padding: 16px 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .header {
        padding-bottom: 40px;
        
        .page-subtitle {
            font-size: 2.5rem;
            margin: 30px 0;
        }

        .intro {
            font-size: 1.6rem;
            margin: 25px 0;
        }

        .text-wrapper {
            padding: 0 20px;

            .container {
                flex-wrap: wrap;

                .btn {
                    margin: 5px 0;
                    padding: 16px 10px;
                    width: 100%;
                }
            }
        }

        .header-options-block {
            .container {
                padding: 20px;
            }

            .section-subtitle {
                font-size: 2rem;
                margin-bottom: 20px;
            }

            .options-list {
                .options-item {
                    margin: 25px 0;
                }
            }
        }
    }
}

@media screen and (max-width: 440px) {
    .header {
        &:before {
            height: 65%;
            top: 35%;
        }

        .page-subtitle {
            font-size: 2.2rem;
            margin: 25px 0;
        }

        .intro {
            font-size: 1.4rem;
            margin: 20px 0;
        }

        .text-wrapper {
            .container {
                .btn {
                    padding: 12px 10px;
                }
            }
        }
    }
}

@keyframes loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}
