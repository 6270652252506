.footer {
    background: $color-background-dark;
    color: $color-white;
    width: 100%;

    .footer-menu-list {
        display: flex;
        flex-direction: column;
        width: 100%;

        .footer-menu-item {
            color: $color-white;
            font-weight: 500;
            margin: 7px 0;

            .link {
                color: $color-white;
                padding: 7px 0;

                &:active,
                &:focus,
                &:hover {
                    color: $color-primary;
                }
            }
        }
    }

    .row {
        align-items: flex-start;
        display: flex;
        padding: 65px 40px;

        .column {
            display: flex;
            flex-direction: column;

            &.social-block {
                align-items: center;
                flex-direction: row;
                flex: none;
                width: 170px;
            }

            &:nth-of-type(1) {
                flex: none;
                width: 200px;
            }

            &:nth-of-type(2) {
                margin-left: 120px;
                width: 15%;
            }

            &:nth-of-type(3),
            &:nth-of-type(4) {
                width: 24%;
            }
        }

        &:last-child {
            height: 145px;
        }
    }

    .footer-logo {
        display: block;
        height: auto;
        width: 172px;

        img {
            display: block;
            height: auto;
            margin: auto;
            width: 100%;
        }
    }

    .btn {
        display: block;
        margin-top: 35px;
        text-align: center;
    }

    .title {
        font-weight: 700;
        letter-spacing: 1px;
        margin: 3px 0 20px 0;
        text-transform: uppercase;
    }

    .hours {
        color: $color-gray-footer;
        font-size: 1.4rem;
    }

    .footer-link {
        color: $color-white;
        transition: $transition-basic;

        &.phone,
        &.mail {
            align-items: center;
            display: flex;
            font-size: 2.1rem;
            margin-bottom: 25px;
        }

        &.phone {
            margin-top: 10px;

            .icon {
                display: inline-block;
                fill: $color-secondary;
                height: 22px;
                margin-right: 21px;
                width: 22px;
            }
        }

        &.mail {
            .icon {
                display: inline-block;
                fill: $color-primary;
                height: 17px;
                margin-right: 20px;
                width: 23px;
            }
        }

        &:active,
        &:focus,
        &:hover {
            &.phone {
                color: $color-secondary;
            }

            &.mail {
                color: $color-primary;
            }
        }
    }

    .copy-row {
        border-top: 1px solid rgba($color-white, .1);

        .wrapper {
            align-items: center;
            display: flex;
            height: 145px;
            position: relative;

            .go-to-top {
                align-items: center;
                background: $color-background-dark;
                border: 1px solid rgba($color-white, .1);
                border-radius: 50%;
                display: flex;
                height: 50px;
                justify-content: center;
                left: 50%;
                position: absolute;
                top: -25px;
                transform: translateX(-50%);
                transition: $transition-basic;
                will-change: transform;
                width: 50px;

                .icon {
                    fill: $color-white;
                    height: 16px;
                    transform: rotate(90deg);
                    transition: $transition-basic;
                    width: 12px;
                }

                &:active,
                &:focus,
                &:hover {
                    background: #333f54;
                    transform: translateX(-50%) scale(1.1);
                }
            }
        }

        .copy-text {
            color: $color-gray-footer;
            display: inline-block;
            font-size: 1.4rem;
            margin-right: 45px;
        }

        .payment {
            align-items: center;
            color: $color-gray-footer;
            display: flex;
            font-size: 1.4rem;
            margin-left: auto;

            img {
                display: block;
                height: auto;
                margin-left: 25px;
                width: 140px;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .footer {
        .row {
            flex-wrap: wrap;
            justify-content: flex-end;

            .column {
                margin-bottom: 35px;
                width: calc((100% / 3) - 40px)!important;

                &:nth-of-type(1) {
                    .btn {
                        width: 200px;
                    }
                }

                &:nth-of-type(5) {
                    margin-left: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .footer {
        .row {
            flex-wrap: wrap;

            .column {
                width: 50%!important;

                &:nth-of-type(2) {
                    margin-left: 0;
                }

                &:nth-of-type(1) {
                    width: 100%!important;
                }
            }
        }

        .copy-row {
            .wrapper {
                flex-wrap: wrap;
                justify-content: center;

                .description {
                    margin: 40px 0 0 0;
                    text-align: center;
                    width: 100%;
                }

                .payment {
                    margin: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .footer {
        .row {
            padding: 45px 30px;

            .column {
                width: 100%!important;
            }
        }

        .copy-row {
            .wrapper {
                height: auto;

                .payment {
                    flex-wrap: wrap;
                    justify-content: center;
                    margin: 20px 0;

                    img {
                        width: 100px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .footer {
        .title {
            font-size: 14px;
            margin-bottom: 15px;
        }

        .footer-menu-list {
            .footer-menu-item {
                font-size: 14px;
                margin: 5px 0;

                a {
                    padding: 5px 0;
                }
            }
        }

        .footer-link.mail,
        .footer-link.phone {
            font-size: 18px;
            margin-bottom: 15px;
        }

        .hours {
            font-size: 12px;
        }

        .row {
            padding: 35px 20px;

            .column {
                margin-bottom: 25px;
            }
        }

        .copy-row {
            .wrapper {
                .description {
                    span {
                        margin: 5px 15px;
                    }
                }
            }
        }
    }
}
