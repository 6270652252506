#main.page {
    .content.error-content {
        text-align: center;
        width: 100%;

        .page-subtitle {
            font-weight: 300;
            font-size: 200px;
            text-align: center;
        }

        .description p {
            font-size: 3rem;
            margin: 25px 0;
        }

        .btn {
            display: inline-block;
        }
    }
}

@media screen and (max-width: 720px) {
    #main.page {
        .content.error-content {
            .page-subtitle {
                font-size: 150px;
            }
    
            .description p {
                font-size: 2.2rem;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    #main.page {
        .content.error-content {
            .page-subtitle {
                font-size: 100px;
            }
    
            .description p {
                font-size: 1.8rem;
            }
        }
    }
}