#main.page {
    border-top: 2px solid $color-border-light;
    margin-top: 140px;
    padding: 70px 0 60px 0;

    .wrapper {
        align-items: flex-start;
        display: flex;
        flex-direction: row-reverse;
    }

    .sidebar-nav {
        background: darken($color-white, 6%);
        color: $color-text;
        flex-shrink: 0;
        margin-right: 40px;
        padding: 40px 45px;
        width: 320px;

        .title {
            border-bottom: 2px solid darken($color-border-light, 8.2%);
            display: block;
            font-size: 2.6rem;
            font-weight: 700;
            margin-bottom: 25px;
            padding-bottom: 25px;
        }

        .sidebar-item {
            font-weight: 600;
            margin: 35px 0;
            position: relative;
            width: 100%;

            .link {
                background: transparent;
                color: $color-text;
                display: block;
                position: relative;
                z-index: 1;
            }

            &:before {
                background: $color-white;
                border-radius: 4px;
                box-shadow: $box-shadow-hover;
                content: '';
                height: 48px;
                left: calc(50%);
                opacity: 0;
                position: absolute;
                top: -15px;
                transition: $transition-basic;
                transform: translateX(-50%) scaleY(.9) scaleX(.9);
                width: calc(100% + 40px);
                z-index: 0;
            }

            &.active,
            &:active,
            &:focus,
            &:hover {
                &:before {
                    opacity: 1;
                    transform: translateX(-50%) scaleY(1) scaleX(1);
                }
            }
        }
    }

    .content {
        color: $color-text;
        padding: 0 40px;

        .page-subtitle {
            color: $color-text;
            margin: 0 0 30px 0;
            text-align: left;
            line-height: 1;
        }

        .intro {
            color: $color-text;
            font-size: 1.8rem;
            font-weight: 600;
            line-height: 1.8;
            margin: 40px 0 15px 0;
        }

        .description {
            p {
                color: $color-text;
                font-size: 1.8rem;
                font-weight: 400;
                line-height: 1.8;
                margin: 10px 0;
            }

            ol {
                li {
                    list-style: decimal;
                }
            }

            ul {
                li {
                    list-style: disk;
                }
            }

            ol, ul {
                font-size: 1.8rem;
                line-height: 1.8;
                margin: 25px 0;
                padding-left: 60px;

                li {
                    margin: 10px 0;
                    padding-left: 10px;
                }
            }
        }

        img {
            display: block;
            height: auto;
            width: 100%;
        }
    }

    .faq-list {
        list-style: decimal;
        padding-left: 20px;

        .faq-item {
            font-size: 20px;
            font-weight: 600;
            list-style: decimal;
            margin-top: 30px;

            .question {
                font-size: 20px;
                margin-bottom: 15px;
            }

            .description {
                font-weight: 400;

                p {
                    margin-top: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    #main.page {
        .content {
            padding: 0 20px;

            .page-subtitle {
                font-size: 4.5rem;
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    #main.page {
        margin-top: 80px;
        padding: 50px 0;

        .content {
            .page-subtitle {
                font-size: 4rem;
            }
        }
    }
}

@media screen and (max-width: 920px) {
    #main.page {
        padding: 30px 0;

        .wrapper {
            flex-wrap: wrap;
        }

        .sidebar-nav {
            padding: 20px 25px;
            width: 100%;

            .title {
                font-size: 2.3rem;
                margin-bottom: 20px;
                padding-bottom: 15px;
            }

            .sidebar-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .sidebar-item {
                    margin: 16px 25px;
                    width: auto;
                }
            }
        }

        .content {
            padding: 40px 10px 0 10px;

            .page-subtitle {
                font-size: 4rem;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    #main.page {
        .content {
            .page-subtitle {
                font-size: 3.5rem;
                margin-bottom: 25px;
            }

            .intro,
            .description p {
                font-size: 1.6rem;
            }

            .description {
                ul, ol {
                    font-size: 1.6rem;
                    padding-left: 30px;
                }

                p {
                    margin: 15px 0;
                }
            }

            img {
                margin: 20px 0;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    #main.page {
        .sidebar-nav {
            .sidebar-list {
                justify-content: flex-start;

                .sidebar-item {
                    width: 100%;
                }
            }
        }

        .content {
            padding: 30px 0 0 0;

            .page-subtitle {
                font-size: 3rem;
            }

            .description {
                ul, ol {
                    padding-left: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    #main.page {
        .content {
            .page-subtitle {
                font-size: 2.5rem;
            }
        }
    }
}
